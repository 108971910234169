import React from "react";
import "../styles/contact.css"
// import "../StyleFile/globalStyles.css"
import CustomRouteButton from "../components/CustomRouteButton";
import callIcon from "../components/icons/icons8-call-100.png";
import emailIcon from "../components/icons/icons8-email-100.png";

export default function ContactUs(props){
    return(
        <>
            <div className="border border-1 bg-light" style={{display: "flex",justifyContent: "center"}}>
                <div className="text-center container row py-lg-5 py-3">
                    <h2 className="fw-light headerHighlight display-3">Contact Us</h2>
                </div>
            </div>
            <div>
                <div className="container">
                    <div className="center-div mt-5">
                        <p>Our dedicated customer support team is available to address your queries and provide personalized assistance. Whether you have questions about our products, orders, or any other concerns, we are here to help. You can reach us by:</p>
                    </div>
                    
                    <div className="row justify-content-center align-items-center my-5">
                        <div className="col-lg-3 mx-5">
                            <div className="card text-center">
                                <div className="card-body">
                                    <h5 className="card-title my-5">Call Us Now</h5>
                                    {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p> */}
                                    <img src={callIcon} width="100" height="100" alt="call"/>
                                    <div className="row justify-content-center my-5">
                                        <CustomRouteButton btnstyle={"btn--outline"} className="btnStyles" animate={true} databstoggle="modal" databstarget="#call">Call Us</CustomRouteButton>    
                                    </div>                            
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 mx-5">
                        <div className="card text-center">
                                <div className="card-body">
                                    <h5 className="card-title my-5">Email Us Now</h5>
                                    {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p> */}
                                    <img src={emailIcon} width="100" height="100" alt="email"/>
                                    <div className="row justify-content-center my-5">
                                        <CustomRouteButton btnstyle={"btn--outline"} className="btnStyles" animate={true} databstoggle="modal" databstarget="#chat">Email</CustomRouteButton>    
                                    </div>                            
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className="my-5 headerHighlight"></hr>
                    <div className="row justify-content-evenly align-items-center my-5">
                        <div className="col-12 col-lg mx-5">
                            <table className="table">
                                <thead className="table-dark">
                                    <tr>
                                    <th scope="Days">Days</th>
                                    <th scope="Hours">Hours</th>
                                    </tr>
                                </thead>
                                <tbody className="table-group-divider text-center">
                                    <tr>
                                    <td>Monday</td>
                                    <td>8 am to 5 pm</td>
                                    </tr>
                                    <tr>
                                    <td>Tuesday</td>
                                    <td>8 am to 5 pm</td>
                                    </tr>
                                    <tr>
                                    <td>Wednesday</td>
                                    <td>8 am to 5 pm</td>
                                    </tr>
                                    <tr>
                                    <td>Thursday</td>
                                    <td>8 am to 5 pm</td>
                                    </tr>
                                    <tr>
                                    <td>Friday</td>
                                    <td>8 am to 5 pm</td>
                                    </tr>
                                    <tr>
                                    <td>Satuday</td>
                                    <td>8 am to 5 pm</td>
                                    </tr>
                                    <tr>
                                    <td>Sunday</td>
                                    <td>Closed</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="col-10 col-lg">
                            <h3>Address</h3>
                            <p> A01/14 Imo Plaza, Trade fair shopping plaza. Balogun Business Associate/Intertional center for commerce. Badagry Express way, Lagos, Nigeria </p>
                        </div>
                    </div>                
                </div>    
            </div>
            
            <div className="modal fade" id="call" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="callLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="callLabel">Phone Number</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body fs-4 ms-2">
                        +(234)-802-544-7083<br />
                        +(234)-803-562-2820
                    </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="chat" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="chatLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="chatLabel">Chat</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body fs-4 ms-2">
                        info@maryjanecollections.com
                    </div>
                    </div>
                </div>
            </div>
        </>
    );
}