import React from "react";
import "../styles/halfImageTextItem.css";
import {Button} from 'react-bootstrap';

const halfImageTextItem = ({ src, text, button, link, title }) => {
    return(
        <div className="parent" >

            <div style={{alignSelf:"center"}} className="child">
                <h1>{text}</h1>
                {button?<Button href={link}>{title}</Button>: null}
            </div>
            <div style={{textAlign: "right"}} className="child">
                <img
                    src = {src}
                    alt="First slide"
                /> 
            </div>
        </div>
    )
}

export default halfImageTextItem;