import React from 'react';
import { Container } from 'react-bootstrap';
import "../styles/services.css"
import AppFace from "../components/appFace.js";
import wrapping from "../components/icons/service-icons/icons8-wrapping-100.svg";
import design from "../components/icons/service-icons/icons8-design-100.svg";
import cosmetic from "../components/icons/service-icons/icons8-cosmetics-100.svg";
import events from "../components/icons/service-icons/icons8-events-100.svg";
import maintenance from "../components/icons/service-icons/icons8-maintenance-100.svg";
import consultation from "../components/icons/service-icons/icons8-consultation-100.svg";
import jewel from "../components/icons/service-icons/icons8-jewel-100.svg";
import skincare from "../components/icons/service-icons/icons8-skincare-100.svg";
import CustomRouteButton from "../components/CustomRouteButton";
import {useNavigate} from "react-router-dom";


function IconText(props) {

  if (props.reversed === true) {
    return (
      <div className='row mt-3 center-div service-list-div'>
        <div className='col middle-div'>
          <AppFace bgColor="linear-gradient(45deg, #f5f5f5, #000000)" IconComponent={props.icon} height="150" width="150" />
        </div>
        <div className='col middle-div'>
          <div bg>
            <div className='service-title-div'>
              <h2 style={{ color: '#e000f3' }}>{props.title}</h2>
            </div>
            <div className='service-text-div'>
              <p>{props.desc}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }else {
    return (
      <div className='row mt-3 center-div service-list-div'>
        <div className='col middle-div order-2 order-sm-2 order-md-1 order-lg-1'>
          <div>
            <div className='service-title-div'>
              <h2 style={{ color: '#e000f3' }}>{props.title}</h2>
            </div>
            <div className='service-text-div'>
              <p>{props.desc}</p>
            </div>
          </div>
        </div>        
        <div className='col middle-div order-1 order-sm-1 order-md-2 order-lg-2'>
          <AppFace bgColor="linear-gradient(45deg, #f5f5f5, #000000)" IconComponent={props.icon} height="150" width="150" />
        </div>
      </div>
    );
  }
}

function Services() {

  const jewelObj = {
    icon: jewel,
    title: "Jewelry Collection",
    desc: "Discover a mesmerizing assortment of jewelry pieces meticulously crafted with love and precision. From stunning rings and elegant necklaces to dazzling earrings and timeless bracelets, our jewelry collection is curated to complement your unique taste and personality."
  }
  
  const designObj = {
    icon: design,
    title: "Custom Jewelry Design",
    desc: "Create a one-of-a-kind masterpiece that reflects your individuality. Our skilled artisans can work closely with you to design a custom piece that perfectly captures your vision. Let your creativity shine as we bring your dream jewelry to life."
  }
  
  const maintenanceObj = {
    icon: maintenance,
    title: "Jewelry Repair and Restoration",
    desc: "Is your treasured piece of jewelry in need of some tender love and care? Our experienced team can skillfully repair and restore your beloved jewelry, bringing it back to its original brilliance."
  }
  
  const cosmeticObj = {
    icon: cosmetic,
    title: "Cosmetics Selection",
    desc: "Indulge in a world of beauty with our carefully curated cosmetics selection. From luxurious skincare products to vibrant makeup essentials, we offer a range of high-quality brands that will elevate your beauty routine."
  }
  
  const consultationObj = {
    icon: consultation,
    title: "Makeup Consultations",
    desc: "Unleash your inner beauty with the help of our professional makeup artists. Book a makeup consultation, and let our experts guide you through personalized tips and techniques to achieve the perfect look for any occasion."
  }
  
  const skincareConsultationObj = {
    icon: skincare,
    title: "Skincare Consultations",
    desc: "Healthy, radiant skin is the foundation of beauty. Our skincare experts are here to analyze your skin type and recommend the best products and routines tailored to your specific needs, ensuring your skin glows with vitality."
  }
  
  const wrappingObj = {
    icon: wrapping,
    title: "Gift Selection and Wrapping",
    desc: "Celebrate special moments with thoughtfully selected gifts from Maryjane. Whether it's a birthday, anniversary, or just to show appreciation, we have a selection of beautiful jewelry and cosmetics that will make any occasion unforgettable. Our gift-wrapping service adds the perfect finishing touch."
  }
  
  const eventsObj = {
    icon: events,
    title: "Beauty Events and Workshops",
    desc: "Join our engaging beauty events and workshops to stay updated on the latest trends and techniques in jewelry and cosmetics. Connect with fellow enthusiasts and learn from industry experts, making your beauty journey even more enjoyable."
  }

  const serviceArray = [cosmeticObj, jewelObj, consultationObj, skincareConsultationObj, wrappingObj, eventsObj, maintenanceObj, designObj];
  
  let navigate = useNavigate();

  return (
    <div id="services" className='home-background pb-5 pt-3'>
        {/* <div className='py-5 centered-div mt-3'>
          <h1>Services</h1>
        </div> */}
        <div className="border border-1 bg-light" style={{display: "flex",justifyContent: "center"}}>
          <div className="text-center container row py-lg-5 py-3 ">
              <h2 className="fw-light display-3">Meet Our Founder</h2>
          </div>
      </div>
      <div className='middle-div mt-5 home-background'>
      <p><span style={{fontSize: "30px", color: "#e000f3"}}><b>Designed to cater to your needs</b></span> <br/><br/> At Maryjane, we provide you with the finest selection of exquisite jewelry and high-quality cosmetics to enhance your beauty and style.<br/> Our services are designed to cater to your needs and ensure a delightful shopping experience.<br/><br/> <span style={{fontSize: "30px", color: "#e000f3"}}><b>Take a glimpse at the wide range of services we offer:</b></span></p>

      </div>
      <Container className='mt-5'>
        {[...Array(4)].map((_, i) => (
          <div key={i} className='row mt-3 center-div'>
            <div className='col middle-div'>
              <IconText icon={serviceArray[2*i].icon} title={serviceArray[2*i].title} desc={serviceArray[2*i].desc} reversed={false}/>
            </div>
            <div className='col middle-div'>
              <IconText icon={serviceArray[2*i+1].icon} title={serviceArray[2*i+1].title} desc={serviceArray[2*i+1].desc} reversed={true}/>
            </div>
          </div>
        ))}
      </Container>
      <div className="row justify-content-center my-3">
          <CustomRouteButton btnstyle={"btn--outline"} className="btnStyles center-div" animate={true} onClick={() => {navigate("/contact")}}>Contact Us</CustomRouteButton>
      </div>
      
    </div>
  );
}

export default Services;