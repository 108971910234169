import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
// import NavigationBar from "./components/NavigationBar";
import Services from "./pages/Services";
import About from "./pages/About";
import Home from "./pages/Home";
import Ceo from "./pages/Ceo";
import ContactUs from "./pages/contactUs";
import ProductPage from "./pages/product";
import ScrollToTop from "./components/helper/scroll";


function App() {

  const importAll = (r) => {
      return r.keys().map((item, index) => {
          return { image: r(item), alt: `img-${index}` };
      });
  }

  const products = importAll(require.context('./components/products', false, /\.(png|jpe?g|svg)$/));

  return (
    <div>
      <Router>
        <ScrollToTop />
        <Header />
        <Routes>
          <Route path="/" exact element={<Home/>} />
          <Route path="/about" element={<About/>} />
          <Route path="/services" element={<Services/>} />
          <Route path="/ceo" element={<Ceo/>} />
          <Route path="/contact" element={<ContactUs/>} />
          <Route path="/products" element={<ProductPage products={products}/>} />
        </Routes>
      </Router>
      {/* <Header /> */}
      {/* <Home id="home" />
      <Services id="services" />
      <About id="about" />
      <Ceo id="ceo" />
      <ContactUs id="contactUs" />
      <ProductPage id="product" products={products}/> */}
      <Footer />
    </div>
  );
}

export default App;
