import React from 'react';
import { Container, Carousel } from 'react-bootstrap';
import '../styles/home.css';
import HalfImageTextItem from '../components/HalfImageTextItem';
import medical from "../components/icons/icons8-medical-100_1_.svg";
import animal from "../components/icons/icons8-animal-100_1_.svg";
import organic from "../components/icons/icons8-leaf-100_1_.svg";
import lotion from "../components/icons/icons8-lotion-100_1_.svg";
import award from "../components/icons/icons8-award-100.svg";
import luxPink from "../components/images/luxPink.png";
import niveaThree from "../components/images/niveaThree.png";
import cosmeticGroup from "../components/images/cosmeticGroup.png";
import niveaCare from "../components/images/niveaCare.png";
import jewellery from "../components/images/jewellery.png";
import stIves from "../components/images/stIves.png";
import palmOlive from "../components/images/palmOlive.png";
import niveaPearl from "../components/images/niveaPearl.png";
import luxWhite from "../components/images/luxWhite.png";
import Mousuf from "../components/images/Mousuf.png";
import niveaGreen from "../components/images/niveaGreen.png";
import lotionModel from "../components/images/lotionModel.png";
import CustomRouteButton from "../components/CustomRouteButton";
import {useNavigate} from "react-router-dom";


function Home(){

  let navigate = useNavigate();

  return (
    <div>
      <div className="App p-3 home-background pt-3"> 

        <Carousel>
            <Carousel.Item>
                <HalfImageTextItem src={luxPink} text="Find your product" button={false}/>
            </Carousel.Item>
            <Carousel.Item>
                <HalfImageTextItem src={niveaThree} text="Amazing products" button={false}/>
            </Carousel.Item>
            <Carousel.Item>
                <HalfImageTextItem src={cosmeticGroup} text="Best Collection Around" button={false}/>
            </Carousel.Item>
        </Carousel> 
        <Container className='row center-div mt-5'>
            <Container className='col'>
              <div className='img-row'>
                <img src={lotion} width="63" height="63" alt="lotion"/>
              </div>
              <div className='three-word-line'>
                <h5>Aamzing Skin Care</h5>
              </div>
            </Container>
            <Container className='col'>
              <div className='img-row'>
                <img src={medical} width="63" height="63" alt="medical"/>
              </div>
              <div className='three-word-line'>
                <h5>Medical Grade</h5>
              </div>
            </Container>
            <Container className='col'>
              <div className='img-row'>
                <img src={award} width="63" height="63" alt="award"/>
              </div>
              <div className='three-word-line'>
                <h5>Best Seller</h5>
              </div>
            </Container>
            <Container className='col'>
              <div className='img-row'>
                <img src={animal} width="63" height="63" alt="animal"/>
              </div>
              <div className='three-word-line'>
                <h5>Animal Friendly</h5>
              </div>
            </Container>
            <Container className='col'>
              <div className='img-row'>
                <img src={organic} width="63" height="63" alt="organic"/>
              </div>
              <div className='three-word-line'>
                <h5>Organic</h5>
              </div>
            </Container>
        </Container>
        <Container className='row center-div mb-3'>
          <div className='col-sm-6'>
            <div>
              <img src={lotionModel} alt="placeholder" className='in-page-product'/>
            </div>
          </div>
          <div className='col-sm-6 in-page-product-text'>
            <div>
              <h2>Our Story</h2>
              <p>Our story is a long one, but it is a good one. Our Journey started with a young girl with a profound fascination for colors, textures, and beauty. Her creativity and keen eye for aesthetics led her to experiment with various makeup looks and jewelry styles. Fuelled by a desire to empower individuals with confidence and elegance, Maryjane pursued formal education in cosmetology, skincare, and gemology. Igniting the spark that would eventually grow into a thriving enterprise.</p>
              <div className="row justify-content-center my-3">
                <CustomRouteButton btnstyle={"btn--outline"} className="btnStyles center-div" animate={true} onClick={() => {navigate("/ceo")}}>Learn More</CustomRouteButton>
              </div>
            {/* <button onClick={() => console.log("hello")} className='mt-3'>learn more</button> */}
            </div>
          </div>
        </Container>
      </div>
      <div className='home-background-two'>
        <Container className='row center-div mb-5 mt-md-3 mt-5'>
          <div className='col-sm-6 in-page-product-text order-2 order-sm-2 order-lg-1'>
            <div>
              <h2>Our Offer</h2>
              <p>Our store is more than just a place to shop; it's an experience that celebrates individuality and self-expression through jewelry and cosmetics.</p>
              <div className="row justify-content-center my-3">
                <CustomRouteButton btnstyle={"btn--solid"} className="btnStyles center-div" animate={true} onClick={() => {navigate("/about")}}>Learn More</CustomRouteButton>
              </div>
            </div>
          </div>
          <div className='col-sm-6 order-1 order-sm-1 order-lg-2'>
            <div>
              <img src={niveaCare} alt="placeholder" className='in-page-product'/>
            </div>
          </div>
        </Container>
        <div className='pre-img-product-3'></div>
        <Container className='row center-div mb-2 mt-md-3 mt-5'>
          <div className='col-sm-6'>
            <div>
              <img src={jewellery} alt="placeholder" className='in-page-product'/>
            </div>
          </div>
        </Container>
        <Container className='row center-div mb-3'>
          <div className='col-sm-6 in-page-product-text increased-width-div'>
            <div>
              <h2>Our Service</h2>
              <p>At Maryjane, our commitment is to provide you with exceptional products and services that exceed your expectations. We look forward to assisting you in finding the perfect jewelry and cosmetics that enhance your unique beauty and style.</p>
              <div className="row justify-content-center my-3">
                <CustomRouteButton btnstyle={"btn--solid"} className="btnStyles center-div" animate={true} onClick={() => {navigate("/services")}}>Learn More</CustomRouteButton>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <div className='center-div mt-5  home-background p-5'>
        <div>
          <h5>Some</h5>
          <h1>Products</h1>
        </div>
        
        <Container className='row center-div mt-3 row-cols-1 row-cols-md-2 row-cols-lg-3 mb-3'>
          <div className='col px-3 py-3'>
            <img src={stIves} alt="placeholder" className='gallery-img'/>
          </div>
          <div className='col px-3 py-3'>
            <img src={palmOlive} alt="placeholder" className='gallery-img'/>  
          </div>
          <div className='col px-3 py-3'>
            <img src={niveaPearl} alt="placeholder" className='gallery-img'/>
          </div>
          <div className='col px-3 py-3'>
            <img src={luxWhite} alt="placeholder" className='gallery-img'/>
          </div>
          <div className='col px-3 py-3'>
            <img src={niveaGreen} alt="placeholder" className='gallery-img'/>
          </div>
          <div className='col px-3 py-3'>
            <img src={Mousuf} alt="placeholder" className='gallery-img'/>
          </div>
          {/* <div className='col px-3 py-3'>
            <img src={niveaGreen} alt="placeholder" className='gallery-img'/>
          </div> */}
        </Container>
        <div className="row justify-content-center my-3">
          <CustomRouteButton btnstyle={"btn--outline"} className="btnStyles center-div" animate={true} onClick={() => {navigate("/products")}}>View More</CustomRouteButton>
        </div>
      </div>
    </div>
  );
}

export default Home;