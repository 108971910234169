import React from 'react';
import '../styles/about.css';

// function About(){
//   return (
//     <div id="about">
//       <div className='py-5 centered-div mt-3'>
//         <h1>About Page</h1>
//       </div>
//     </div>
//   );
// }

const Intro = () => {
  return (
    <div>
      <h2>Introducing Maryjane Onyeka: Founder and CEO of Maryjane Collection</h2><br/> <br/><p>Welcome to Maryjane Collection, a haven for exquisite jewelry and luxurious cosmetics. Behind the captivating allure of our products stands an extraordinary woman, Maryjane Onyeka, the visionary founder, and CEO of this renowned brand. With an unrivaled passion for beauty and an unyielding entrepreneurial spirit, Maryjane has carved her name in the cosmetics industry as a true trailblazer.</p>
    </div>
  );
}

const Expertise = () => {
  return (
    <div>
      <h2>A Journey of Passion and Expertise</h2>
      <p>Maryjane Onyeka's journey in the cosmetics business began as a young girl with a profound fascination for colors, textures, and beauty. Her creativity and keen eye for aesthetics led her to experiment with various makeup looks and jewelry styles, igniting the spark that would eventually grow into a thriving enterprise.<br/><br/>Fuelled by a desire to empower individuals with confidence and elegance, Maryjane pursued formal education in cosmetology, skincare, and gemology. Her dedication to mastering the art of makeup application, skincare solutions, and gemstone knowledge set the foundation for Maryjane Collection's commitment to excellence.</p>
    </div>
  );
}

const Revolutionizing = () => {
  return (
    <div>
      <h2>Revolutionizing the Cosmetics Industry</h2>
      <p>Maryjane Onyeka's relentless pursuit of perfection and continuous innovation has played a pivotal role in revolutionizing the cosmetics industry. Her brand introduced a diverse range of high-quality skincare products, cosmetics, and jewelry collections, each meticulously crafted to elevate the beauty experience.<br/><br/> Maryjane's ethos of enhancing natural beauty and promoting self-expression through makeup has resonated with millions worldwide. Her dedication to sustainable and cruelty-free beauty practices has inspired customers and industry peers alike to embrace ethical choices.</p>
    </div>
  );
}

const Empower = () => {
    return (
      <div>
        <h2>Empowering Women</h2>
        <p>Maryjane Onyeka's journey in the cosmetics business extends beyond her role as a successful entrepreneur. She has consistently used her platform to advocate for women's empowerment and upliftment. Through various initiatives and partnerships, she has supported organizations that promote education, healthcare, and equal opportunities for women.</p>
      </div>
    );
  }

  const Legacy = () => {
    return (
      <div>
        <h2>The Maryjane Collection Legacy</h2>
        <p>Today, Maryjane Onyeka's visionary leadership has made Maryjane Collection a global icon of sophistication and beauty. From Hollywood celebrities to everyday makeup enthusiasts, her brand has won the hearts of countless individuals seeking timeless elegance and unparalleled quality.<br/><br/>Maryjane continues to lead her team with passion, fostering a culture of creativity and inclusivity. As a brand, Maryjane Collection stands tall as a symbol of empowerment, reminding everyone that beauty is not just skin deep—it's an art to be cherished and celebrated.<br/><br/>We invite you to explore our enchanting collection of jewelry and cosmetics, curated by none other than Maryjane Onyeka herself. Embark on a journey of beauty and self-discovery and experience the magic that has made Maryjane Collection a beacon of excellence in the cosmetics business.</p>
      </div>
    );
  }



const About = () => {
  return (
    <div className="about">
      {/* <div className='py-5 home-background centered-div '>
        <h1 className="title">Meet Our Founder</h1>
      </div> */}
      <div className="border border-1 home-background" style={{display: "flex",justifyContent: "center"}}>
          <div className="text-center container row py-lg-5 py-3 ">
              <h2 className="fw-light display-3">Meet Our Founder</h2>
          </div>
      </div>
      <div className='center-div about-container'>
        <Intro />
        <Expertise />
        <Revolutionizing />
        <Empower />
        <Legacy />
        <p>Follow Maryjane on Linkedin: <a href="url">https://www.linkedin.com/in/onkem/</a></p>
      </div>
    </div>
  );
}


export default About;