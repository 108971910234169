import React, {useState} from 'react';
import Navbar  from 'react-bootstrap/Navbar';
import { Nav, Container} from 'react-bootstrap';
import { Link } from 'react-router-dom';


function NavigationBar() {

  const [expanded, setExpanded] = useState(false);  // manage the Navbar.Toggle state

  return (
 
    <Navbar  expand="lg" expanded={expanded} className='home-background'>

      <Container fluid className='center-div'>

        

        <Navbar.Toggle  onClick={() => setExpanded(expanded ? false : "expanded")} aria-controls="basic-navbar-nav" />

        <Navbar.Collapse id="basic-navbar-nav">

          <Nav className="ml-auto center-div">

          <Navbar.Brand as={Link} to="/">MJ</Navbar.Brand>

            <Nav.Link as={Link} to="/services">Services</Nav.Link>

            <Nav.Link as={Link} to="/about">About</Nav.Link>

            <Nav.Link as={Link} to="/contact">Contact</Nav.Link>

            <Nav.Link as={Link} to="/products">Shop</Nav.Link>

            <Nav.Link as={Link} to="/ceo">Ceo</Nav.Link>

          </Nav>

        </Navbar.Collapse>

      </Container>

    </Navbar>

  );

}

export default NavigationBar;