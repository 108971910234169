import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CustomRouteButton from "../components/CustomRouteButton";
import {useNavigate} from "react-router-dom";

const ProductCard = ({ product }) => {
  return (
    <div class="card" style={{ paddingLeft: '1px', paddingRight: '1px', paddingTop:'2px', paddingBottom: '2px', marginBottom: '20px' }}>
        <div class="card-body">
            {product.header && <h5 class="card-title text-center">{product.header}</h5>}
            <img class="card-img-top" src={product.image} alt={product.image} style={{ objectFit: 'contain', height: '500px' }}/>
            {product.price && <h5 className="card-text text-center mt-1">{product.price}</h5>}
        </div>
    </div>
  );
};

const ProductPage = ({ products }) => {
  const productRows = [];
  for (let i = 0; i < products.length; i += 3) {
    productRows.push(products.slice(i, i + 3));
  }

  let navigate = useNavigate();

  return (
    <div className='home-background py-3'>
        <div className="border border-1 bg-light" style={{display: "flex",justifyContent: "center"}}>
            <div className="text-center container row py-lg-5 py-3">
                <h2 className="fw-light headerHighlight display-3">Products</h2>
            </div>
        </div>
        <div className="center-div mt-5">
            <p>Take a look at some of products you can find in our store:</p>
        </div>
        <Container className='mt-5'>
            {productRows.map((row, idx) => (
                <Row key={idx} className='mb-4 justify-content-center'>
                {row.map((product, index) => (
                    <Col key={index} sm={12} md={4}>
                    <ProductCard product={product} />
                    </Col>
                ))}
                </Row>
            ))}
        </Container>
        <div className="row justify-content-center my-3">
          <CustomRouteButton btnstyle={"btn--outline"} className="btnStyles center-div" animate={true} onClick={() => {navigate("/contact")}}>Contact Us</CustomRouteButton>
      </div>
    </div>
    
  );
};


export default ProductPage;