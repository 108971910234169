import React from 'react';

function Footer() {
  return (
    <>
    <div className="footer">
     icons by <a target="_blank" rel="noreferrer" href="https://icons8.com">Icons8</a>
    </div>
    <footer className='footer'>
      <div className='row center-div'>
        <div className='col'>
          <a href="/contact">Contact Us</a>
        </div>
        <div className='col'>

          <a target="_blank" href="/contact" style={{marginRight: "20px"}} rel="noreferrer">Facebook</a>

          <a target="_blank" href="/contact" rel="noreferrer">Linkedin</a>
        </div>
      </div>

      <p>Copyright &copy; 2023 MJ Collections</p>
    </footer>
    </>
  );
}

export default Footer;