import React from 'react';
import '../styles/about.css';

// function About(){
//   return (
//     <div id="about">
//       <div className='py-5 centered-div mt-3'>
//         <h1>About Page</h1>
//       </div>
//     </div>
//   );
// }

const Journey = () => {
  return (
    <div>
      <h2>Our Journey</h2>
      <p>Established with a passion for all things beautiful, Maryjane has been a trusted name in the industry since 2013. Over the years, we have curated an exquisite collection of jewelry and cosmetics that cater to a diverse range of tastes and preferences. Our journey has been one of discovery, embracing new trends while cherishing timeless classics.</p>
    </div>
  );
}

const Experience = () => {
  return (
    <div>
      <h2>The Maryjane Experience</h2>
      <p>Step into our world of elegance, and you'll find a carefully curated selection of jewelry that showcases the craftsmanship of talented artisans from around the world. From delicate necklaces that accentuate your neckline to statement rings that speak volumes about your style, our jewelry collection is designed to be as unique as you are. <br/> <br/> But we don't stop at just jewelry; our cosmetics range is equally exceptional. Whether you're looking for the perfect shade of lipstick to make a statement or nourishing skincare products that pamper your skin, our cosmetics selection has you covered. We take pride in offering high-quality products from renowned brands that have stood the test of time.</p>
    </div>
  );
}

const Approach = () => {
  return (
    <div>
      <h2>Customer-Centric Approach</h2>
      <p>At Maryjane, we believe that every customer deserves to feel special and cared for. Our friendly and knowledgeable team is always here to assist you, whether you need help choosing the ideal gift or selecting the perfect shade of foundation. Your satisfaction is our top priority, and we go the extra mile to ensure that your shopping experience with us is nothing short of exceptional.</p>
    </div>
  );
}

const Ethics = () => {
  return (
    <div>
      <h2>Ethics</h2>
      <p>Ethical Sourcing and Sustainability: We are committed to ethical sourcing practices, ensuring that the materials used in our jewelry are responsibly and sustainably obtained. Our cosmetics range includes products that are cruelty-free and environmentally friendly, reflecting our dedication to protecting the planet and its inhabitants.</p>
    </div>
  );
}

// const Team = () => {
//   // add your team members here
//   const teamMembers = ['John Doe', 'Jane Doe', 'Robert Smith'];

//   return (
//     <div>
//       <h2>Our Team</h2>
//       {teamMembers.map(member => (
//         <p>{member}</p>
//       ))}
//     </div>
//   );
// }

const MissionAndVision = () => {
  return (
    <div>
      <h2>Mission & Vision</h2>
      <p>Write your company's mission and vision here.</p>
    </div>
  );
}


const Values = () => {
  return (
    <div>
      <h2>Our Values</h2>
      <p>Write your company's values here.</p>
    </div>
  );
}

const About = () => {
  return (
    <div className="about">
      {/* <div className='py-5 home-background centered-div'>
        <h1 className="title">About Us</h1>
      </div> */}
      <div className="border border-1 home-background" style={{display: "flex",justifyContent: "center"}}>
          <div className="text-center container row py-lg-5 py-3 ">
              <h2 className="fw-light display-3">About Us</h2>
          </div>
      </div>
      <div className='center-div about-container'>
        <Journey />
        <Experience />
        <Approach />
        <Ethics />
        {/* <Team /> */}
        <MissionAndVision />
        <Values />
      </div>
      
    </div>
  );
}


export default About;