import React from 'react';

const AppFace = ({ bgColor, IconComponent, height, width }) => {
    const appFaceStyle = {
        background: bgColor,
        height: `${height}px`,
        width: `${width}px`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '25%',
    };

    return (
        <div style={appFaceStyle}>
            <img src={IconComponent} alt="lotion"/>
        </div>
    );
};

export default AppFace;